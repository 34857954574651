import React, {useState, useEffect} from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import {Link, Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";
import parse from "html-react-parser";

const PrivacyPolicyPage: React.FC = ({data: {
    allWpPage
}}) => {

    const {t} = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(true);
    const {language, changeLanguage} = useI18next();

    let pageDetailsFull;

    //  Check language
    if (language !== 'en') {

        pageDetailsFull = allWpPage?.nodes[0];


    }else{

        pageDetailsFull = allWpPage?.nodes[0]?.translations[0];
        
    }

    return (
        <Layout>

            <SEO title={"Privacy Policy"} />
            <div className="body-wrap">

                <section className="content">

                    <div className="container">

                        <div className="row">

                            <h1>{pageDetailsFull?.title}</h1>

                        </div>


                        <div className="row mt-5">

                            <div className="page-content">

                                {
                                    parse((pageDetailsFull?.content) ? pageDetailsFull.content : '')
                                }

                            </div>

                        </div>

                    </div>

                </section>

            </div>

        </Layout>
    );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allWpPage(filter: {databaseId: {eq: 713}}) {
    nodes {
        title
        content
      ,
      translations{
        title
        content
      }
    }
  }
  }
`;